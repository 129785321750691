<div >
    <router-outlet></router-outlet>

  <calsoft-loader></calsoft-loader>
</div>
<ng-template #dialogRef let-mydata>
  <div class="pushmsg-card">
      <h5><b>{{mydata.title}}</b></h5>
      <h6>{{mydata.body}}</h6>
      <img src="{{mydata.image}}" class="push-imageview"/><br>
      <button mat-button mat-dialog-close="Closed push Message...">Close</button>
  </div>
</ng-template>
