<div *ngIf="display">

    <div *ngIf="url!='/home'">
        <mat-toolbar-row fxLayoutAlign="start center" style="height: auto;">

            <div fxFlex="5">

            </div>
            <div fxLayout="row" fxFlex="20" fxLayoutGap="10px">
                <section class="padding-10">

                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

                </section>
            </div>

            <div fxLayout="row" fxLayoutGap="10px" fxFlex="65">

                <calsoft-Menu [color]="false" [data]="data" [type]="'one'" style="color: #000000; z-index: 1000;"
                    class="menu" [categoryArray]="item"></calsoft-Menu>

            </div>

            <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">
                <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
                    <mat-icon style=" cursor: pointer;" fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                        (click)="showSearchBar()">search</mat-icon>
                </div>


                <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                    fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="350px">
                    <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                        [searchbarborderRadius]="'15px'"></calsoft-search>
                </div>

            </div>

            <div fxLayoutAlign="end center" fxFlex="10"
                *ngIf="url !== '/cart' && url !== '/checkout' && url !=='/checkout/type' ">

                <button (click)="wishlist()" mat-button *ngIf="url != '/account/wishlist'">
                    <mat-icon>favorite_border</mat-icon>

                </button>

                <calsoft-cart-icon [color]="true"></calsoft-cart-icon>

            </div>

            <div *ngIf="!userLoggedIn" class="_sadh" (click)="SignIn('login')" fxLayoutAlign="start center">
                <button mat-button class="w-color" *ngIf="!userLoggedIn"> <mat-icon style="color: black;">person</mat-icon>
                </button>
            </div>
    
            <calsoft-HeaderUserProfileDropdown  [color]="'white'" [data]="data" class="headerUser" *ngIf="userLoggedIn">
            </calsoft-HeaderUserProfileDropdown>

        </mat-toolbar-row>
    </div>

    <div *ngIf="url=='/home'" class="header">
        <div class="header-wrapper" fxLayout="row" >
            <div fxLayout="row" fxFlex fxLayoutAlign="start center" >
                <mat-toolbar-row fxHide fxShow.gt-sm class="container" fxLayout="row" fxLayout.xs="row" >
                    <calsoft-Menu [color]="true" [data]="data" [type]="'one'" style="color: #000000;" class="menu"
                        [categoryArray]="item"></calsoft-Menu>
                </mat-toolbar-row>

                <section fxLayoutAlign="center center">
                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
                </section>
               

            </div>
            <div fxLayout="row" fxFlex="45"  fxLayoutAlign="end center">

                <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">
                    <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
                        <mat-icon style=" cursor: pointer; color: white;" fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                            (click)="showSearchBar()">search</mat-icon>
                    </div>
    
    
                    <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                        fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="300px">
                        <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                            [searchbarborderRadius]="'15px'"></calsoft-search>
                    </div>
    
                </div>

                <div fxFlex="20px">

                </div>

                <div *ngIf="url!='/account/wishlist'">
                    <button style="color: white;" (click)="wishlist()" mat-icon-button matBadgePosition="after" matBadgeColor="accent">
                        <i class="material-icons notranslate">
                            favorite_border
                        </i>
                    </button>
                </div>
                
                <calsoft-cart-icon [color]="false" ></calsoft-cart-icon>


                <div *ngIf="!userLoggedIn" class="_sadh" (click)="SignIn('login')" fxLayoutAlign="start center">
                    <button mat-button class="w-color" *ngIf="!userLoggedIn"> <mat-icon style="color: white;">person</mat-icon>
                    </button>
                </div>
        
                <calsoft-HeaderUserProfileDropdown [data]="data" class="headerUser" *ngIf="userLoggedIn">
                </calsoft-HeaderUserProfileDropdown>

            </div>

        </div>
    </div>

    <mat-toolbar fxHide fxShow.gt-md class="calsoft-fixed-header primary" fxLayout="row"
    fxLayoutAlign="space-around center" style="height: auto; z-index: 1000;">

    <mat-toolbar-row class="theme-container" fxLayoutAlign="space-between center" style="height: auto;" >

    <div fxLayout="row" fxLayoutGap="10px" fxFlex="80">

        <calsoft-Menu [color]="true" fxLayoutAlign="center center" style="z-index: 1000;" [data]="data" [type]="'type'"
            [categoryArray]="item"></calsoft-Menu>
            

            <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
    </div>

    

    <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">
        <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
            <mat-icon style=" cursor: pointer; color:white"  fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                (click)="showSearchBar()">search</mat-icon>
        </div>


        <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
            fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="300px">
            <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                [searchbarborderRadius]="'15px'"></calsoft-search>
        </div>

    </div>
    <div fxFlex="2">

    </div>

    <div fxLayoutAlign="end center" *ngIf="url != '/cart'">

        <button style="color: white;" (click)="wishlist()" mat-icon-button matBadgePosition="after" matBadgeColor="accent">
            <i class="material-icons notranslate">
                favorite_border
            </i>
        </button>

        <calsoft-cart-icon [color]="false"></calsoft-cart-icon>

    </div>
    
    <!-- <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
        <div style="color: #ffffff" fxLayout="column">
            <small>Hello, Sign in</small>
            <span>Account & Lists</span>
        </div>
    </button>

    <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser" *ngIf="userLoggedIn">
    </calsoft-HeaderUserProfileDropdown> -->

    <div fxFlex="3">

    </div>

    </mat-toolbar-row>
</mat-toolbar>

</div>

<div *ngIf="!display">
    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>
</div>