import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Constant } from "src/app/constants/constant";
import { UserService } from "./user.service";
import { shareReplay, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CartService {
  public responseCache1 = new Map();

  public responseCacheWish = new Map();

  url = environment.serverAPI3 + "/" + "api/cart";

  url2 = environment.serverAPI3 + "/" + "api/ig/cart";

  url1 = environment.serverAPI3 + "/" + "api";

  cartCount: Response[];

  constructor(
    private http: HttpClient,
    private userService: UserService
    ) {}

  addCartItem(cart) {
    this.cachedCartCount$ = undefined;
    this.cachedCartItemCountValue$=undefined;
  let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
     
      return this.http.post<Response[]>(`${this.url}`, cart);
      
    }else{

      cart['cartId']=localStorage.getItem(Constant['CART_ID']);
  
      return this.http.post<Response[]>(`${this.url2}`, cart);
    }
    
  }



  getPayment(cart:any): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url1}/owpa/payment`, cart);
  }

  getPaymentStatus(cart:any): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url1}/owpa/payment/status`, cart);
  }


  private cachedCartCount$: Observable<any>;

  cartItemCount(): Observable<any> {
    
    if (!this.cachedCartCount$) {
      this.cachedCartCount$ = this.getCartCountNew();
    }
    
    return this.cachedCartCount$;
  }
  
  getCartCountNew(): Observable<any> {
    let authorized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled = localStorage.getItem(Constant['GUEST_LOGIN']);
    const isGuestLoginEnabled = guestLoginEnabled === "0";
  
    let request$: Observable<any>;
  
    if (isGuestLoginEnabled || authorized) {
      request$ = this.http.get<any>(`${this.url}/customer/cartCount`).pipe(shareReplay(1));
    } else {
      const headers = new HttpHeaders({
        [Constant['CART_ID']]: localStorage.getItem(Constant['CART_ID']) || ''
      });
      const options = {
        headers: headers
      };
      request$ = this.http.get<any>(`${this.url2}/customer/cartCount`, options).pipe(shareReplay(1));
    }
  
    return request$;
  }

  private cachedCartItemCountValue$: Observable<any>;

  cartItemCountValue(): Observable<any> {
    if (!this.cachedCartItemCountValue$) {
      this.cachedCartItemCountValue$ = this.getCartItemCountValue();
    }
    
    return this.cachedCartItemCountValue$;
  }
  
  getCartItemCountValue(): Observable<any> {
    let authorized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled = localStorage.getItem(Constant['GUEST_LOGIN']);
    const isGuestLoginEnabled = guestLoginEnabled === '0';
  
    let request$: Observable<any>;
  
    if (isGuestLoginEnabled || authorized) {
      request$ = this.http.get<any>(`${this.url}/customer/cartCount`);
    } else {
      const headers = new HttpHeaders({
        [Constant['CART_ID']]: 'saas'
      });
      const options = {
        headers: headers
      };
      request$ = this.http.get<any>(`${this.url2}/customer/cartCount`, options);
    }
  
    return request$.pipe(shareReplay(1));
  }



  getCount() {
    this.cartItemCount().subscribe(
      (count) => {
        this.cartCount = count;
      },
      (err) => {}
    );
  }
  getCount1() {
    this.cartItemCountValue().subscribe(
      (count) => {
        this.cartCount = count;
      },
      (err) => {}
    );
  }

  // getCartItemByUserId(customerId: number): Observable<Object> {
  // 	return this.http.get(`${this.url}/customer/${customerId}`);
  // }

  public getCartItemByUserId(cache: any, page: any): Observable<any> {
 
  let authourized = this.userService.getAuthenticatedUser();
  let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
  if(guestLoginEnabled=="0"||authourized){
      return this.http.get<any>(`${this.url}/customer`);
    }else{
      let headers = new HttpHeaders({
        [Constant['CART_ID']] :localStorage.getItem(Constant['CART_ID']),
      });
      let options = {
        headers: headers,
      };
      return this.http.get<any>(`${this.url2}/customer`,options);
    }
  
  }

  public getPrescriptionStatus(): Observable<any> {

    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
      return this.http.get(`${this.url}/customer/prescription`);
    
    }else{

      let headers = new HttpHeaders({
        [Constant['CART_ID']] :localStorage.getItem(Constant['CART_ID']),
      });
      let options = {
        headers: headers,
      };
      return this.http.get(`${this.url2}/customer/prescription`, options);
    }

   
  }


  deleteById(): Observable<Object> {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
      return this.http.delete(`${this.url}`);
    }else{
      let entityId=localStorage.getItem(Constant['CART_ID'])
      return this.http.delete(`${this.url2}/${entityId}`);
    }
   
  }

  deleteItemFromCart(
    quoteId: number,
    itemId: number,
    salesFlatQuoteItem
  ): Observable<Object> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: headers,
      body: salesFlatQuoteItem,
    }; 
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
      return this.http.delete(`${this.url}/${quoteId}/${itemId}`,options);
    }else{
      let headers = new HttpHeaders({
        "Content-Type": "application/json",
      });
      let options = {
        headers: headers,
        body: salesFlatQuoteItem,
      }; 
      return this.http.delete(`${this.url2}/${quoteId}/${itemId}`, options);
    }

   
  }

  outOfstock(entityId: number, Page: number, size: number): Observable<Object> {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    
    if(guestLoginEnabled=="0"||authourized){
    return this.http.get(
      `${this.url}/outOfstock/${entityId}?page="${Page}+"&size="${size};`
    );
    }else{
      let headers = new HttpHeaders({
        [Constant['CART_ID']] :localStorage.getItem(Constant['CART_ID']),
      });
      let options = {
        headers: headers,
      };
      console.log("asas");
      return this.http.get(`${this.url2}/outOfstock/${entityId}?page=${Page}&size=${size}`, options);
      
    }
  }

  removeOutOfStock(salesFlatQuoteItem): Observable<Object> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers: headers,
      body: salesFlatQuoteItem,
    };
    return this.http.delete(`${this.url}/removeOutOfStockItems`, options);
  }

  public getCustomerCartProductCount(): Observable<any> {
    return new Observable(observer => {
      let authorized = this.userService.getAuthenticatedUser();
      let guestLoginEnabled = localStorage.getItem(Constant['GUEST_LOGIN']);
  
      if (guestLoginEnabled === "0" || authorized) {
        const cartFromCache = this.responseCache1.get(`${this.url}/customer/count`);
  
        if (cartFromCache !== undefined) {
          observer.next(cartFromCache);
          observer.complete();
        } else {
          this.http.get<any>(`${this.url}/customer/count`)
            .pipe(
              tap((value) => this.responseCache1.set(`${this.url}/customer/count`, value))
            )
            .subscribe((value) => {
              observer.next(value);
              observer.complete();
            });
        }
      } else {
        const cartFromCache = this.responseCache1.get(`${this.url2}/customer/count`);
  
        if (cartFromCache !== undefined) {
          observer.next(cartFromCache);
          observer.complete();
        } else {
          let cartId = localStorage.getItem(Constant['CART_ID']);
  
          if (cartId !== null) {
            let headers = new HttpHeaders({
              [Constant['CART_ID']]: localStorage.getItem(Constant['CART_ID']),
            });
            let options = { headers: headers };
  
            this.http.get<any>(`${this.url2}/customer/count`, options)
              .pipe(
                tap((value) => this.responseCache1.set(`${this.url2}/customer/count`, value))
              )
              .subscribe((value) => {
                observer.next(value);
                observer.complete();
              });
          }
        }
      }
    });
  }

  public geWishListProduct  (): Observable<any> {
    let authorized = this.userService.getAuthenticatedUser();

    if (authorized) {
      const wishFromCache = this.responseCacheWish.get(`${this.url1}/wishlist/product/list`);
  
      if (wishFromCache !== undefined) {
        return of(wishFromCache);
      } else {
        return this.http.get<any>(`${this.url1}/wishlist/product/list`).pipe(
          tap((value) => {
            this.responseCacheWish.set(`${this.url1}/wishlist/product/list`, value);
          })
        );
      }
    } else {
      return of(null); // Return an Observable with a default value for unauthorized users
    }
  }


  
  getWishListValue(){
    let authourized = this.userService.getAuthenticatedUser();
    let value=null;
    if (authourized) {
      value =this.responseCacheWish.get(
      `${this.url1}/wishlist/product/list`
    );
    }

    return value;
  }


  getCartValue(){
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN']);
    let response: any = null;
    let value=null;
    if(guestLoginEnabled=="0"||authourized){
      value =this.responseCache1.get(
      `${this.url}/customer/count`
    );
    }else{
      value =this.responseCache1.get(
        `${this.url2}/customer/count`
        );
    }

    return value;
  }
  setCartValue(value) {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN']);
    let response: any = null;
    if(guestLoginEnabled=="0"||authourized){
    this.responseCache1.set(`${this.url}/customer/count`, value);
    }else{
      this.responseCache1.set(`${this.url2}/customer/count`, value);
    }
  }

  setWishValue(value) {
    let authourized = this.userService.getAuthenticatedUser();

    if (authourized) {
      this.responseCacheWish.set(`${this.url1}/wishlist/product/list`, value);
    }
  }

  getCartCount() {
    let value = localStorage.getItem(Constant.CART_COUNT);
    if (value != null) {
      value = atob(value);
      value = JSON.parse(value);
    } else {
      value = null;
    }
    return value;
  }

  setCartCount(){
    
    this.getCustomerCartProductCount().subscribe(
      (response) => {


      },
      (err) => {}
    );
  }

  
}
