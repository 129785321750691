<mat-menu #menu   >
    <span  class="theme-container">
    <ng-container *ngFor="let item of items$">
  
      <a  [style.color]="data.categoryColor"	(click)="goToCategory(item)" *ngIf="item.subcategories.length==0; else branch" mat-menu-item>{{item.categoryName}}</a>
      <ng-template #branch>
        <a [style.color]="data.categoryColor" mat-menu-item [matMenuTriggerFor]="innerPanel.menu" (click)="goToCategory(item)">{{item.categoryName}}</a>
        
        <calsoft-menu2 [data]="data" (mouseenter)="menuenter()" (mouseleave)="menuLeave(levelOneTrigger, button)" #innerPanel [items$]="item.subcategories"></calsoft-menu2>
      </ng-template>
    </ng-container>
    </span>
  </mat-menu>
