<ng-container *ngIf="list && list.length >0">
  <div class="theme-container" *ngFor="let subChild of list" (click)="goToCategory(subChild)">




    <span [style.color]="data.categoryColor" class="subMenus pointer theme-container" *ngIf="display">
  
      {{subChild.categoryName}}
    </span>


    <a *ngIf="!display" (click)="goToCategory(subChild)" [style.color]="data.categoryColor" mat-menu-item>
      {{subChild.categoryName}}</a>

    <calsoft-nested-child [data]="data" [list]="subChild.subcategories"></calsoft-nested-child>

  </div>


</ng-container>



