<div class="navbar theme-container">
    <a href="javascript:void(0)" (click)="leftMenuToggle()">
      <div class="bar-style">
        <!-- <i class="fa fa-bars sidebar-bar" aria-hidden="true"></i> -->
        <mat-icon  class=" sidebar-bar">menu</mat-icon> 
        </div>
    </a>
    <div id="mySidenav" class="sidenav" 
    [class.openSide]="navServices?.leftMenuToggle">
      <a href="javascript:void(0)" class="sidebar-overlay" (click)="leftMenuToggle()" 
                                                           (mouseover)="onHover(false)"></a>
      <nav id="unset">
        <div (click)="leftMenuToggle()">
          <div class="sidebar-back text-start">
            <mat-icon  class="sub-arrow1 pe-2">arrow_back_ios</mat-icon> 
             Back
          </div>
        </div>
        <ul id="sub-menu" class="sidebar-menu">
          <li *ngFor="let menuItem of categoryArray" (mouseover)="onHover(menuItem.children ? true : false)">
              <!-- Sub -->
              <a href="javascript:void(0)" 
                  (click)="toggletNavActive(menuItem)">
                  {{menuItem.categoryName}}
                 <!-- <span class="sub-arrow"  *ngIf="menuItem.children"></span> -->
                <mat-icon  class="sub-arrow1" *ngIf="menuItem.subcategories.length>0">arrow_forward_ios</mat-icon>   
              </a>
              
              <!-- 2nd Level Menu -->
              <ul [class]="menuItem.mega=='true' ? 'mega-menu categoryArray' : ''" 
              [class.opensub1]="menuItem.isActive=='1'?true:false" *ngIf="menuItem.subcategories.length>0">
  
                <!-- Simple Menu Start-->
                <ng-container *ngIf="menuItem.mega=='false'">
              
                  <li *ngFor="let childrenItem of menuItem.subcategories">
  
                    <a class="has-submenu">
                      {{childrenItem.categoryName}}
                   </a>
                  
                    <!-- 3rd Level Menu -->
                    <ul *ngIf="childrenItem.subcategories.length>0">
                
                      <li *ngFor="let childrenSubItem of childrenItem.subcategories">
                          <!-- Link -->
                          <a [routerLink]="childrenSubItem.path" >
                            {{childrenSubItem.categoryName}}
                          </a>
                         
                          <!-- 4th Level Menu -->
                          <ul *ngIf="childrenSubItem.subcategories.length>0">
                            <li *ngFor="let childrenSubSubItem of childrenSubItem.subcategories">
                                <!-- Link -->
                                <a [routerLink]="childrenSubSubItem.path" 
                              >
                                   {{childrenSubSubItem.categoryName}}
                                </a>
                              
                                <!-- 5th Level Menu -->
                                <ul *ngIf="childrenSubSubItem.children">
                                  <li *ngFor="let childrenSubSubSubItem of childrenSubSubItem.children">
                                      <!-- Link -->
                                      <a [routerLink]="childrenSubSubSubItem.path" *ngIf="childrenSubSubSubItem.type === 'link'">
                                         {{childrenSubSubSubItem.title}}
                                      </a>
                                      <!-- External Link -->
                                      <a href="{{ childrenSubSubSubItem.path }}" *ngIf="childrenSubSubSubItem.type === 'extLink'">
                                        {{childrenSubSubSubItem.title}}
                                      </a>
                                      <!-- External Tab Link -->
                                      <a href="{{ childrenSubSubSubItem.path }}" target="_blank" *ngIf="childrenSubSubSubItem.type === 'extTabLink'">
                                         {{childrenSubSubSubItem.title}}
                                      </a>
                                  </li>
                                </ul>
                            </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ng-container>
                <!-- Simple Menu End-->
        
                     <!-- Mega Menu Start-->
                     <ng-container *ngIf="menuItem.mega=='true'">
            
                    <li>
                      <div class="row m-0">
                        <div class="col-xl-4" *ngFor="let childrenItem of menuItem.subcategories">
                          <div class="link-section">
                            <h5>{{childrenItem.categoryName}}</h5>
                            
                              <!-- <li *ngFor="let childrenSubItem of childrenItem.subcategories">
                              
                                  <a [routerLink]="childrenSubItem.path" >
                                     {{childrenSubItem.categoryName}}
                                  </a>
                               
                              </li> -->
                              <app-leftmenu-sub 
                              [category]="childrenItem.subcategories"></app-leftmenu-sub>
                             
                          </div>
                        </div>
                        <div class="col-xl-4">
                            <a [routerLink]="['/home/one']" class="mega-menu-banner"><img src="" alt="img-fluid" class="img-fluid"></a>
                        </div>
                      </div>
                    </li>
                    </ng-container>
                <!-- Mega Menu End-->
              </ul>
            </li>
        </ul>
      </nav>
    </div>
  </div>
  
  
