<footer class="footer-v1" style="max-width: 100%;">
    <calsoft-subscriber></calsoft-subscriber>

    <div class="theme-container">
    <div fxLayout="row" fxLayout.lt-md="column"  fxLayoutAlign="space-around start" 
    class="py-3 border-bottom-mute" 
>        
<div fxFlex="10" 
></div>
        <div fxFlex="20" > 
            <h3 class="col-title">Discover</h3>

            <p  class="mt-2"><a routerLink="/aboutus" >About Us</a></p>
            <p *ngIf="data.showContactPage==1" class="mt-1"><a routerLink="/contact-us" >Contact Us</a></p>
            <p *ngIf="data.showBlogPage==1" class="mt-1"><a routerLink="/blog" >Blog</a></p>
            <p *ngIf="data.disableCoupoun==1" class="mt-1"><a routerLink="/coupon" >Coupon</a></p>
            <p *ngIf="data.showBrochurePage==1" class="mt-1"><a routerLink="/brochure" >Brochure</a></p>

        </div>
        <div fxFlex="20" ngClass.xs="mt-2"> 
            <h3 class="col-title">Policy</h3>

            <p class="mt-2"><a routerLink="/returnPolicy" >Refund / Cancellation Policy</a></p>
            <p class="mt-1"><a routerLink="/terms&conditions" >Terms & Conditions</a></p>
            <p class="mt-1"><a routerLink="/privacy" >Privacy Policy</a></p>
            <p class="mt-1"><a routerLink="/shippingPolicy" >Shipping Policy</a></p>
            <p class="mt-1"><a routerLink="/paymentPolicy" >Payment Policy</a></p>
        </div>
       
        <div fxFlex="20" fxFlex.gt-sm="30" fxFlex.sm="60" ngClass.xs="mt-2" class="contact-information"> 
            <h3 class="col-title">CONTACT INFORMATION</h3>            
           
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
              <a (click)="callPhone()" style="cursor: pointer; display: flex; align-items: center;">
                <mat-icon>call</mat-icon>
                <span class="footer-color" style="margin-left: 8px;">{{ data.storePhoneNumber }}</span>
              </a>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>mail_outline</mat-icon>
                <span>&nbsp;&nbsp;{{data.smtpUsername}}</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color">
                <mat-icon style="padding-right: 15px;">location_on</mat-icon>
                <span>{{data.streetAddress}}</span>
            </p>
            
        </div>
         <div fxFlex="10" ngClass.xs="mt-2" *ngIf="showFooterIcon==1" > 
            <h3 class="col-title">Social Media</h3>

            <div  >
                <span fxFlex="30"  *ngFor="let item of footerIconData; let i = index" >
               
              <button  (click)="navigate(item)" mat-icon-button >
             
              <mat-icon [svgIcon]="item.iconName"> </mat-icon>
            </button>
            </span>
           
          </div>

        </div>
        <div fxFlex="20" 
></div>
        
    
    </div>
    <div class="footer-bottom-v1" style="margin: 1px 0px; ">
          <div fxLayout='row wrap' fxLayout.lt-md="column" class="col-gap" fxLayoutAlign="space-between center" >
            <div fxFlex="1"  >
  
            </div>
            <div fxFlex  >
              <img src="assets/images/cards.png" width="218"  alt="Credit Cards">
            </div>
            
            <div fxFlex *ngIf="data.dspeedupNavigationEnable == 1">
              <a class="underline-on-hover" target="_blank" href="https://dspeedup.com/">Powered by dSPEEDUp</a>
            </div>
          
            <div fxFlex  >
              <small>Copyright © {{data.storeName}} {{currentYear}}. All rights reserved.</small>
            </div>
            
      
            <div *ngIf="data.vendorEnabled == 1" class="padding-30" fxLayoutAlign="start center">
              <a class="border padding-10" (click)="Supplierlogin()" href="javascript:void(0)">Become a Supplier</a>
            </div>
            
          
          </div>
        </div>
        </div>
        <div *ngIf="footerImageEnable==1">
        <div *ngIf="display">
          <div style="margin-top:auto;" >
            <img [src]="serverPath+footerLogoSrc" onerror="this.src='';" style="width: 100%;"/>
          </div>
        </div>
        </div>
        
</footer>