import { isPlatformBrowser, PlatformLocation,Location } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constant } from './constants/constant';
import { AnalyticService } from './services/analytic-service';
import { ServiceWorkerService } from './services/service-worker.service';
import { UrlService } from './services/url-service.service';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {PushmsgService } from 'src/app/services/pushmsg.service';
import { ShareChatTrackingService } from './services/share-chat-tracking.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,OnDestroy {
  //@ViewChild('dialogRef')
  public serverPath = environment.commonImageApi + "logo/logo.png";
  public serverPath4 = environment.commonImageApi + "favImage/fav.png";
  private routerSubscription: Subscription;
  //dialogRef!: TemplateRef<any>;
  @ViewChild('dialogRef') dialogRef: TemplateRef<any>;


  url: any;
  url1: any;
  subscription: any;
  browserRefresh: boolean;
  refresh: boolean;
  previousUrl: string = null;
  currentUrl: string = null;
  //pushPayload: INotificationPayload;
  hasPermission:any;
  token:any;
  data: any;

  constructor(
    public router: Router,
    private location: Location,
    public platform1: PlatformLocation,
    private urlService: UrlService,
    private swUpdate: ServiceWorkerService,
    private analyticService:AnalyticService,
    private title: Title,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
    private push_msg:PushmsgService,
    private _activatedRoute: ActivatedRoute,
    private shareChatTrackingService: ShareChatTrackingService,
  ) {
    this.swUpdate.checkForUpdates();

  }

 
  ngOnInit(): void {

   

   
    
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
      if (this.currentUrl.includes("/pr?sid=")) {

        let url = this.currentUrl.split("sid=")[1];

        this.urlService.setBreadCrumpId(url);
      }

      if (this.previousUrl == "/orders" && this.currentUrl == "/checkout") {
        this.router.navigate(['/']);
      }      

      if(localStorage.getItem(Constant.GOOGLE_ANALYTICS_ENABLED)!=undefined&&localStorage.getItem(Constant.GOOGLE_ANALYTICS_ENABLED)!=''){
        this.analyticService.pageView(event.url,this.title.getTitle())
      }

      if (localStorage.getItem('shareChat')!=undefined&&localStorage.getItem('shareChat')!='') {
        
         this.shareChatTrackingService.initializeTracking
         (localStorage.getItem('shareChat'));
      }
      

    });
    this.startStopTimer();

  }



  ngAfterViewInit(){
    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.startStopTimer();
        if (isPlatformBrowser(this.platformId)) {
         //  window.scrollTo(0,0);
        }
      }
    })
  }

  private timeoutId: any;

  startStopTimer() {
    // Clear previous timeout (if any)
    clearTimeout(this.timeoutId);

    // Start a new timeout for 5 minutes
    this.timeoutId = setTimeout(() => {
      window.stop();
    }, 45000);
  }




  @HostListener('window:popstate') backbuttonpressed() {
    const currentUrl = this.router.url;
    if (currentUrl === "/home"&&navigator['app']) {
      navigator['app'].exitApp();
    } 
  }



  ngOnDestroy() {
    // Unsubscribe from the router events and clear the timeout
    this.routerSubscription.unsubscribe();
    clearTimeout(this.timeoutId);
  }

  openTempDialog(payload) {
    console.log(JSON.stringify(payload));
    //alert('Notification: '+ payload.title + ' --- '+ payload.body)
    
    const mydata = this.dialog.open(this.dialogRef, { data: payload });
    mydata.afterClosed().subscribe((res) => {

      // Data back from dialog
      console.log({ res });
    });
    
  }

  
}
