import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from 'src/app/services/nav.service';


@Component({
  selector: 'calsoft-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  @Input() categoryArray: any;
  public menuItems: any[] = [];

  constructor(private router: Router, public navServices: NavService) {
    this.navServices.leftMenuItems.subscribe(categoryArray => this.categoryArray = categoryArray );
    this.router.events.subscribe((event) => {
    //  console.log(event)
      this.navServices.mainMenuToggle = false;
    });
  }
  ngOnInit(): void {
  }
  
  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item : any) {
    item.active = !item.active;
  }

  onHover(menuItem: any) {
    if(window.innerWidth > 1200 && menuItem){
       document.getElementById('unset').classList.add('sidebar-unset')
    } else {
      document.getElementById('unset').classList.remove('sidebar-unset')
    }
  }

}